import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "reactstrap";

import styles from "./Loader.module.scss";

/**
 * https://reactstrap.github.io/?path=/docs/components-spinner--spinner
 */
const Loader = ({
  className,
  color,
  hasBackgroundShade = false,
  isModal = false,
  isOverlay = false,
  size,
  type = "border",
}) => (
  <div
    aria-busy="true"
    aria-label="Loading..."
    aria-live="polite"
    aria-modal={isModal}
    className={classNames(styles.loader, className && className)}
    data-background-shade={hasBackgroundShade}
    data-overlay={isOverlay}
    role={isModal ? "alertdialog" : "alert"}
  >
    <Spinner className={styles.spinner} color={color} size={size} type={type} />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  hasBackgroundShade: PropTypes.bool,
  isModal: PropTypes.bool,
  isOverlay: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default Loader;
